// Variable overrides
// $sidebarNavLinkSel: #4e5256;
// $sidebarNavLinkSelText: #fff;
// $sidebarNavLinkSelActive: #4e5256;
// $sidebarNavLinkSelActiveText: #fff;

$sidebarBgColor: #303030ff;
$andamiosYellowColor: #303030ff;

$sidebarNavLinkSelText: #fff;
$sidebarNavLinkSelActiveText: #fff;
$sidebarNavLinkSel: #23282c;
$sidebarNavLinkSelActive: #1e71c5;


$primary: #0277bd !default;
// $secondary:     $gray-300 !default;
$success:       #007E33 !default;
$info:          #0099CC !default;
$warning:       #FF8800 !default;
$danger:        #CC0000 !default;
// $light:         $gray-100 !default;
// $dark:          $gray-800 !default;