
ReactTable .rt-tbody .rt-td {
    border-right: 1px solid red;
}

.react-datepicker-wrapper{
	display: block !important;
}

.react-datepicker__input-container{
	display: block !important;
}

/* utility classes margins and paddings*/

.mt-30{
	margin-top: 30px
}

.mt-27{
	margin-top: 27px
}

.mt-25{
	margin-top: 25px
}

.mt-20{
	margin-top: 20px
}

.mt-15{
	margin-top: 15px
}

.mt-10{
	margin-top: 10px
}

.mb-30{
	margin-bottom: 30px
}

.mb-27{
	margin-bottom: 27px
}

.mb-25{
	margin-bottom: 25px
}

.mb-20{
	margin-bottom: 20px
}

.mb-15{
	margin-bottom: 15px
}

.mb-10{
	margin-bottom: 10px
}

/*-------------------- PARA CHECKBOXES ----------------*/
/*input[type="checkbox"]{
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background: #ccc;
  border-radius: 1px;
  box-sizing: border-box;
  position: relative;
  box-sizing: content-box ;
  width: 30px;
  height: 30px;
  border-width: 0;
  transition: all .3s linear;
  }

  input[type="checkbox"]:checked{
    background-color: #2ECC71;
  }

  input[type="checkbox"]:focus{
    outline: 0 none;
    box-shadow: none;
  }*/


  /*------------------------------------------------------------------------------*/
  /*------------------------------------------------------------------------------*/
  /* Customize the label (the container) */
  .cont-ch {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .cont-ch-normal{
    display: inline-block;
    position: relative;
    padding-left: 35px;
    /*margin-bottom: 12px;*/
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .cont-ch input, .cont-ch-normal input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: solid thin #ccc;
  }

  /* On mouse-over, add a grey background color */
  .cont-ch:hover input ~ .checkmark, .cont-ch-normal:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .cont-ch input:checked ~ .checkmark, .cont-ch-normal input:checked ~ .checkmark {
    /* background-color: 	#999999; */
    background-color: #2196F3;
  }

  .cont-ch input[disabled]:checked ~ .checkmark, .cont-ch-normal input[disabled]:checked ~ .checkmark {
    background-color: 	#999999;
    /* background-color: #2196F3; */
  }


  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .cont-ch input:checked ~ .checkmark:after, .cont-ch-normal input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .cont-ch .checkmark:after, .cont-ch-normal .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }


  /*------------------------------------------------------------------------------*/
  /*------------------------------------------------------------------------------*/

  /* Customize the label (the container) */
  .cont-rb {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .cont-rb-normal{
    display: inline-block;
    position: relative;
    padding-left: 35px;
    /*margin-bottom: 12px;*/
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .cont-rb input, .cont-rb-normal input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark-rb{
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
    border: solid thin #ccc;
  }

  /* On mouse-over, add a grey background color */
  .cont-rb:hover input ~ .checkmark-rb, .cont-rb-normal:hover input ~ .checkmark-rb {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  .cont-rb input:checked ~ .checkmark-rb, .cont-rb-normal input:checked ~ .checkmark-rb {
    background-color: #2196F3;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark-rb:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .cont-rb input:checked ~ .checkmark-rb:after, .cont-rb-normal input:checked ~ .checkmark-rb:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .cont-rb .checkmark-rb:after, .cont-rb-normal .checkmark-rb:after {
    top: 8px;
    left: 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }


  .rc-time-picker-input{
    border: none !important;
    padding: 0px !important;
    height: auto !important;
  }

  .object-fit-cover{
    object-fit: cover;
  }

/* Para darle estilo select de bootstrap a react-select */
.form-control.css-2b097c-container {
    padding: 0px;
    height: max-content;
}

.form-control .css-yk16xz-control {
    background-color: #ffffff00 !important;
    border-style: none !important;
}

.form-control .css-1pahdxg-control {
    border-color: #80bdff !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.text-body-secondary {
  font-size: 0.9rem;
  line-height: 0.925rem;
  letter-spacing: 0px;
  color: rgba(51, 48, 60, 0.6);
}