// Here you can add other styles

.app{
    
    header.app-header.navbar{

        .navbar-toggler{
            color: white;
            .navbar-toggler-icon{
                color: white !important;
            }
        }
    
    }
    
    .app-header{
        .navbar-brand.navsidebar-dynamic-bg{
            width: 210px;
            background-color: $sidebarBgColor;
        }
    }

    .sidebar {
        background: $sidebarBgColor;
        .sidebar-header{
            background: $sidebarBgColor;
        }
    }

    .sidebar .nav-dropdown .nav-dropdown-items .nav-link {
        color: #fff;
        border-left: 0;
        font-size: 85%;
        // padding-left: 10px !important;
        padding: 12px 16px 12px 25px;
    }
    
    .sidebar .nav-dropdown-toggle {
        position: relative;
        background: $sidebarBgColor !important;
    }
        
    .sidebar .nav-link:hover{
        color: $sidebarNavLinkSelText !important;
        background: $sidebarNavLinkSel;
    }
    
    .sidebar .nav-link.active{
        color: $sidebarNavLinkSelActiveText !important;
        font-weight: bold;
        background: $sidebarNavLinkSelActive;
    }
    
    .sidebar .nav-link.active .nav-icon {
        color: #fff;
    }

}

.card{
    border-radius: 4px;
    border-width: 1px;
    border-color: #a6a8aa;
}

.card-header:not(.normal){
    // background-color: white !important;
    background-color: #4e5256 !important;
    color: white;
    font-size: 14pt;
    font-weight: bold;

}

.card-header.bg-primary:not(.normal){
    background-color: $primary !important;
    font-size: 14pt;
    font-weight: bold;
}

.card-header.bg-info:not(.normal){
    background-color: $info !important;
    font-size: 14pt;
    font-weight: bold;
}

.card-header.bg-secondary:not(.normal){
    background-color: $secondary !important;
    font-size: 14pt;
    font-weight: bold;
}

.card-header.bg-success:not(.normal){
    background-color: $success !important;
    font-size: 14pt;
    font-weight: bold;
}

// .sidebar .nav-dropdown .nav-dropdown-items .nav-link {
//     color: #fff;
//     border-left: 0;
//     font-size: 85%;
//     // padding-left: 10px !important;
//     padding: 12px 16px 12px 12px;
// }

// .sidebar .nav-dropdown-toggle {
//     position: relative;
//     background: #2f353a;
// }

// .sidebar .nav-link:hover{
//     color: $sidebarNavLinkSelText !important;
//     background: $sidebarNavLinkSel;
// }

// .sidebar .nav-link.active{
//     color: $sidebarNavLinkSelActiveText !important;
//     font-weight: bold;
//     background: $sidebarNavLinkSelActive;
// }

// .sidebar .nav-link.active .nav-icon {
//     color: #fff;
// }

.main .container-fluid {
    padding: 0 15px;
}

.dropdown-menu .dropdown-item{
    border: none;
}

.dropdown-item i{
    color: black;
}

// para contextmenu
.react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    color: #373a3c;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
}

.react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #373a3c;
	cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
    color: #878a8c;
}

.react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
	padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: "▶";
    display: inline-block;
    position: absolute;
    right: 7px;
}

.example-multiple-targets::after {
    content: attr(data-count);
    display: block;
}

.react-contextmenu-wrapper{
    display: inline-block !important;
}

label {font-weight: bold;}

// ESTILOS PARA RC-STEPS

.rc-steps-navigation{
    padding-top: 0px !important;
}

.rc-steps-navigation .rc-steps-item-title {
    font-size: 16px;
}

.rc-steps-item-container{
    display: block !important;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
}

@include media-breakpoint-down(md) {
    .app{
        .app-header{
            .navbar-brand.navsidebar-dynamic-bg {
                background-color: transparent;
            }
        }
    }
}

@media (min-width: 992px){

    .brand-minimized .app-header .navbar-brand {
        width: 50px !important;
        background-color: transparent;
    }

    .sidebar-fixed .sidebar, .sidebar .sidebar-nav, .sidebar .nav {
        width: 210px;
    }
    
    .sidebar .sidebar-header {
        padding: 0.5rem 1rem 0.3rem 1rem;
    }

    html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
        margin-left: 210px;
    }

    html:not([dir="rtl"]) .sidebar {
        margin-left: -210px;
    }
    

}