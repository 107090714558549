// Here you can add other styles
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');

body{
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    // font-family: 'Roboto', sans-serif;
}

.modal-dialog{
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12) !important;
}

// IMAGES

.andamios-img-thumb{

    display      : inline-flex;
    border-radius: 2px;
    border       : 2px solid #eaeaea;
    margin-bottom: 8px;
    margin-right : 8px;
    width        : 120px;
    height       : 120px;
    padding      : 4px;
    box-sizing   : border-box;


    .andamios-thumb-inner{

        display  : flex;
        min-width: 0px;
        overflow : hidden;

        img{
            display: block;
            width  : auto;
            height : 100%;
            cursor: pointer;
        }

        .andamios-inner{
            position    : absolute;
            top         : 10px;
            left       : 25px;
            margin-top  : 2px;
            margin-right: 2px;
        }
    }
}

// styles for containers

.card-with-shadow:not(.normal){
    border: none;
    border-radius: 5px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(59, 62, 102, 0.03), 
    0 0.9375rem 1.40625rem rgba(59, 62, 102, 0.03), 
    0 0.25rem 0.53125rem rgba(59, 62, 102, 0.05), 
    0 0.125rem 0.1875rem rgba(59, 62, 102, 0.03);

    .card-header:not(.normal){
        border-bottom-color: #dee1e4;
        font-family: "Heebo",sans-serif;
        font-weight: 700;
    }

}

.footer-contacto.content > img,
    .footer-contacto.content > span {
        display: inline-block;
    }



.alert-env-message{
    position: fixed;
    vertical-align: middle;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    top: 0;
    left: 0;
    right: 0;
    width: 55%;
    margin: 0px auto;
    z-index: 9999;
    text-align: center;
    background-color: orange;
    color: black;
    padding: 8px 5px;

    font-size: 12pt;

    .simple-env-title{
        display: inline;
        margin-right: 5px;
        // font-size: 10pt;
    }

    .simple-env-subtitle{
        display: inline;
        // font-size: 10pt;
    }

    &:hover{
        transition: 0.5s;
        background-color: transparent;
        color: orange;
        font-weight: bold;
    }

}

.badge-circle{
    width: 22px;
    height: 22px;
    border-radius: 50%;
    vertical-align: middle;
    display: inline-block;
    padding-top: 7px;
}